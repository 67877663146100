<template>
  <v-form>
    <h1 class="pa-4">ตารางการเช็คอินวันนี้</h1>
   
    <v-row align="center" justify="center" class="pa-8">
       <v-col cols="12" style="margin: 30px;">
    <v-card height="60vh" >
        <gmap-map :center="centerPosition" :zoom="12">
          <gmap-marker
            :key="index"
            v-for="(m, index) in items"
            :position="m.position"
          >
          <gmap-info-window
              :opened="true"
            >
              จุดเช็คอินที่ {{index + 1}}
            </gmap-info-window>
          </gmap-marker>
        </gmap-map>
      </v-card>
    </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary">สร้างผู้ใช้งาน</v-btn>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersCategory"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <!-- <template v-slot:[`item.name`]="{ item }">
              <span>{{ item.firstname }} {{ item.lastname }}</span>
            </template> -->
            <template v-slot:[`item.userType`]="{ item }">
              <span v-if="item.userType == 'USER'">ประชาชน</span>
              <span v-if="item.userType == 'POLICE_1'">สายตรวจ</span>
              <span v-if="item.userType == 'POLICE_2'">ตำรวจจราจร</span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
              <span>{{ convertDate(item.createdAt) }} น.</span>
            </template>
            <template v-slot:[`item.profileImg`]="{ item }">
              <v-img
                :src="item.profileImg"
                max-width="100px"
                class="ma-1"
              ></v-img>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="viewUser(item)">mdi-eye</v-icon>
                <v-icon @click="UpdateUser(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteUser(item)">mdi-delete</v-icon>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  data() {
    return {
      centerPosition: {
        lat: 13.7324414,
        lng: 100.5436332,
      },
      headersCategory: [
        { text: "ลำดับ", value: "count", align: "center" },
        // { text: "รูป", value: "profileImg", align: "center" },
        { text: "วันที่-เวลา", value: "createdAt", align: "center" },
        { text: "ชื่อ", value: "name", align: "center" },
        // { text: "ตำแหน่ง", value: "position", align: "center" },
        // { text: "หมายเลขโทรศัพท์", value: "tel", align: "center" },
        // { text: "รหัสวิทยุ", value: "radioCode", align: "center" },
        // { text: "ประเภท", value: "userType", align: "center" },
        // { text: "verify", value: "isVerify", align: "center" },
        // { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      tabs: "",
      tabitems: [
        { text: "ผู้ใช้งาน", key: "ผู้ใช้งาน" },
        // { text: "แอดมิน", key: "แอดมิน" },
      ],
      items: [],
      search: "",
      count: 0,
      tel: "",
    };
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY hh:mm");
    },
    async getAllUsers() {
      var checkinData = JSON.parse(
        Decode.decode(localStorage.getItem("checkinData"))
      );
      console.log("checkinData", checkinData);
      this.tel = checkinData.tel;
      // const auth = {
      //   headers: { Authorization: `Bearer ${user.token}` },
      // };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/checkins/findAllByUser/` + this.tel
        // ,auth
        // 'http://localhost:8081/categories/'
      );
      console.log("users", response.data.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        this.items[i].position = {
          lat: this.items[i].lat * 1,
          lng: this.items[i].lng * 1
        }
      }

      this.centerPosition.lat = this.items[0].position.lat
      this.centerPosition.lng = this.items[0].position.lng
    },
    // async getAllAdmins() {
    //   var user = JSON.parse(
    //     Decode.decode(localStorage.getItem("userPoontana"))
    //   );
    //   const auth = {
    //     headers: { Authorization: `Bearer ${user.token}` },
    //   };
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/users/findAllAdmin`,
    //     auth
    //     // 'http://localhost:8081/categories/'
    //   );
    //   console.log("users", response.data);
    //   this.items = response.data.data;
    //   for (let i in this.items) {
    //     this.items[i].count = parseInt(i) + 1;
    //   }
    // },
    async UpdateUser(val) {
      localStorage.setItem("userdata", Encode.encode(val));
      this.$router.push("edituser");
    },
    viewUser(val) {
      localStorage.setItem("userdata", Encode.encode(val));
      //   console.log(val);
      this.$router.push("viewUser");
    },
    async DeleteUser(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        // cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // var user = JSON.parse(
          //   Decode.decode(localStorage.getItem("userPoontana"))
          // );
          // const auth = {
          //   headers: { Authorization: `Bearer ${user.token}` },
          // };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/users/` + val.id,
            {}
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllUsers();
        }
      });
    },
    goToCreate() {
      this.$router.push("createuser");
    },
    tab(val) {
      if (val.text == "แอดมิน") {
        this.getAllAdmins();
      }
      if (val.text == "ผู้ใช้งานทั่วไป") {
        this.getAllUsers();
      }
    },
  },
};
</script>